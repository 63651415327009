<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <div class="settings-content">
    <h1>{{ $t('setting.setting') }}</h1>
    <p class="subtitle">{{ $t('setting.setting_title') }}</p>

    <div v-if="isLogin">
      <div class="card user-card">
        <h2>{{ $t('setting.basic_info') }}</h2>
        <div class="user-info">
          <img :src="userPicture" class="avatar">
          <div class="user-details">
            <p class="user-detail"><span class="detail-label">{{ $t('setting.user_name') }}:</span> <span class="detail-content">{{ userName }}</span></p>
            <p class="user-detail"><span class="detail-label">{{ $t('setting.user_email') }}:</span> <span class="detail-content">{{ email }}</span></p>
          </div>
        </div>
      </div>
      <div class="card account-card">
        <h2>{{ $t('setting.user_account') }}</h2>
        <p class="user-detail"><span class="detail-label">{{ $t('setting.user_subscript') }}:</span> <span class="detail-content">{{ subType }}</span></p>
        <a :href="stripePortalLink" class="btn">{{ $t('setting.manage_subscript') }}</a>
      </div>
    </div>
    <div v-else class="settings-message-container">
      <p class="settings-message">
        {{ $t('setting.not_logged_in') }}
        <a @click="redirectToLogin" class="settings-link">{{ $t('setting.go_to_login') }}</a>
      </p>
    </div>

    <!-- div class="card">
      <h2>Usage</h2>
      <div class="usage-bar">
        <div class="usage-progress" :style="{ width: usagePercentage + '%' }"></div>
      </div>
      <p>You've used <span>{{ usedChars }}</span> out of your <span>{{ totalChars }}</span> monthly character limit.</p>
    </div> -->
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { reactive } from 'vue'
import axios from 'axios'
import { setCookie, delCookie, getCookie } from '../utils/cookies';

// 异步加载组件
const VoiceHeader = defineAsyncComponent(() => import('../components/VoiceHeader.vue'))
const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

export default {
  name: 'settings',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  data() {
    return {
      isLogin: 'true',
      email: '',
      userName: '',
      userPicture: '',
      user_subscript: 0,  // 有4个取值, 0 未订阅, 1 已订阅, 2 虽订阅,字符已用完
      customEmail: '',
      subscript_type: '',
      subType: '',
      subPeriod: '',
      usedChars: 0,
      totalChars: 10000,
      stripePortalLink: 'https://billing.stripe.com/p/login/14k8xPeixaRC9H25kk'
    }
  },
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'authToken'])
  },
  // computed: {
  //   usagePercentage() {
  //     return (this.usedChars / this.totalChars) * 100
  //   }
  // }
  methods: {
    async getUserInfo (email) {
      if (!email) {
        console.log("email is empty, email:" + email)
	      return false
      }
      try {
        const host = this.$i18n.t('host')
        const uri = `${host}/lapi/user/profile`
        const { data } = await axios.get(uri, {
          params: { email },
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })

        if (data.ret === 0 && data.user_info) {
          this.email = data.user_info.email
          this.userName = data.user_info.full_name
          this.userPicture = data.user_info.imgage_url
          this.user_subscript = data.user_info.user_subscript
          this.usedChars = data.user_info.used_char_count
          this.totalChars = data.user_info.total_usable
          this.customEmail = data.user_info.customEmail
          var userEmail = this.email
          if (this.customEmail && this.customEmail.trim() !== '') {
            userEmail = this.customEmail
          }
          this.stripePortalLink =  this.stripePortalLink + "?prefilled_email=" + encodeURIComponent(userEmail)
          this.subscript_type = data.user_info.subscript_type
          if (this.subscript_type === 'starter_month') {
            this.subType = "Starter"
            this.subPeriod = "Month"
          } else if (this.subscript_type === 'starter_year') {
            this.subType = "Starter"
            this.subPeriod = "Year"
          } else if (this.subscript_type === 'pro_month') {
            this.subType = "Pro"
            this.subPeriod = "Month"
          } else if (this.subscript_type === 'pro_year') {
            this.subType = "Pro"
            this.subPeriod = "Year"
          } else {
            this.subType = "Free"
            this.subPeriod = "Free"
          }
        } else {
          console.log("ret:" + data.ret + ", msg:" + data.msg)
        }
      } catch (error) {
        console.error('Error fetching user information:', error)
        console.log('An error occurred while fetching user information, please try again later!')
      }
    },
    redirectToLogin() {
      this.$router.push('/account/login');
    }
  },
  async mounted (){
    const storedUserData = getCookie('userData');
    // console.log('home page 22, storedUserData:', storedUserData)
    if (storedUserData) {
      this.isLogin = true
      const userData = JSON.parse(storedUserData)
      // console.log('home page 33, user Data email:', userData.email)
      await this.getUserInfo(userData.email)
    } else {
      this.isLogin = false
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/second_style.css';
.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin-bottom: 20px;
}
/* ... (include other styles from the original HTML) ... */
</style>

